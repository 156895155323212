import React, { useState, useRef } from "react";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import Select from 'react-select';
import { createUser } from '../../../graphql/mutations';
import { generateClient } from "aws-amplify/api";
import { uploadData } from 'aws-amplify/storage';
import imageCompression from 'browser-image-compression';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import BGImg from "../../../assets/images/bread_crumb_bg.png";

const client = generateClient();

const countryGrownOptions = [
    { value: "🇦🇫 Afghan", label: "🇦🇫 Afghan" },
    { value: "🇦🇱 Albanian", label: "🇦🇱 Albanian" },
    { value: "🇩🇿 Algerian", label: "🇩🇿 Algerian" },
    { value: "🇦🇴 Angolan", label: "🇦🇴 Angolan" },
    { value: "🇦🇷 Argentine", label: "🇦🇷 Argentine" },
    { value: "🇦🇲 Armenian", label: "🇦🇲 Armenian" },
    { value: "🇦🇺 Australian", label: "🇦🇺 Australian" },
    { value: "🇦🇹 Austrian", label: "🇦🇹 Austrian" },
    { value: "🇦🇿 Azerbaijani", label: "🇦🇿 Azerbaijani" },
    { value: "🇧🇭 Bahraini", label: "🇧🇭 Bahraini" },
    { value: "🇧🇩 Bangladeshi", label: "🇧🇩 Bangladeshi" },
    { value: "🇧🇾 Belarusian", label: "🇧🇾 Belarusian" },
    { value: "🇧🇪 Belgian", label: "🇧🇪 Belgian" },
    { value: "🇧🇿 Belizean", label: "🇧🇿 Belizean" },
    { value: "🇧🇯 Beninese", label: "🇧🇯 Beninese" },
    { value: "🇧🇹 Bhutanese", label: "🇧🇹 Bhutanese" },
    { value: "🇧🇴 Bolivian", label: "🇧🇴 Bolivian" },
    { value: "🇧🇦 Bosnian", label: "🇧🇦 Bosnian" },
    { value: "🇧🇼 Botswanan", label: "🇧🇼 Botswanan" },
    { value: "🇧🇷 Brazilian", label: "🇧🇷 Brazilian" },
    { value: "🇧🇳 Bruneian", label: "🇧🇳 Bruneian" },
    { value: "🇧🇬 Bulgarian", label: "🇧🇬 Bulgarian" },
    { value: "🇧🇫 Burkinabé", label: "🇧🇫 Burkinabé" },
    { value: "🇧🇮 Burundian", label: "🇧🇮 Burundian" },
    { value: "🇨🇦 Canadian", label: "🇨🇦 Canadian" },
    { value: "🇨🇻 Cape Verdean", label: "🇨🇻 Cape Verdean" },
    { value: "🇨🇫 Central African", label: "🇨🇫 Central African" },
    { value: "🇹🇩 Chadian", label: "🇹🇩 Chadian" },
    { value: "🇨🇱 Chilean", label: "🇨🇱 Chilean" },
    { value: "🇨🇳 Chinese", label: "🇨🇳 Chinese" },
    { value: "🇨🇴 Colombian", label: "🇨🇴 Colombian" },
    { value: "🇰🇲 Comorian", label: "🇰🇲 Comorian" },
    { value: "🇨🇬 Congolese", label: "🇨🇬 Congolese" },
    { value: "🇨🇷 Costa Rican", label: "🇨🇷 Costa Rican" },
    { value: "🇭🇷 Croatian", label: "🇭🇷 Croatian" },
    { value: "🇨🇺 Cuban", label: "🇨🇺 Cuban" },
    { value: "🇨🇾 Cypriot", label: "🇨🇾 Cypriot" },
    { value: "🇨🇿 Czech", label: "🇨🇿 Czech" },
    { value: "🇩🇰 Danish", label: "🇩🇰 Danish" },
    { value: "🇩🇯 Djiboutian", label: "🇩🇯 Djiboutian" },
    { value: "🇩🇴 Dominican", label: "🇩🇴 Dominican" },
    { value: "🇪🇨 Ecuadorian", label: "🇪🇨 Ecuadorian" },
    { value: "🇪🇬 Egyptian", label: "🇪🇬 Egyptian" },
    { value: "🇸🇻 Salvadoran", label: "🇸🇻 Salvadoran" },
    { value: "🇬🇶 Equatorial Guinean", label: "🇬🇶 Equatorial Guinean" },
    { value: "🇪🇷 Eritrean", label: "🇪🇷 Eritrean" },
    { value: "🇪🇪 Estonian", label: "🇪🇪 Estonian" },
    { value: "🇪🇹 Ethiopian", label: "🇪🇹 Ethiopian" },
    { value: "🇫🇯 Fijian", label: "🇫🇯 Fijian" },
    { value: "🇫🇮 Finnish", label: "🇫🇮 Finnish" },
    { value: "🇫🇷 French", label: "🇫🇷 French" },
    { value: "🇬🇦 Gabonese", label: "🇬🇦 Gabonese" },
    { value: "🇬🇲 Gambian", label: "🇬🇲 Gambian" },
    { value: "🇬🇪 Georgian", label: "🇬🇪 Georgian" },
    { value: "🇩🇪 German", label: "🇩🇪 German" },
    { value: "🇬🇭 Ghanaian", label: "🇬🇭 Ghanaian" },
    { value: "🇬🇷 Greek", label: "🇬🇷 Greek" },
    { value: "🇬🇹 Guatemalan", label: "🇬🇹 Guatemalan" },
    { value: "🇬🇳 Guinean", label: "🇬🇳 Guinean" },
    { value: "🇬🇼 Bissau-Guinean", label: "🇬🇼 Bissau-Guinean" },
    { value: "🇬🇾 Guyanese", label: "🇬🇾 Guyanese" },
    { value: "🇭🇹 Haitian", label: "🇭🇹 Haitian" },
    { value: "🇭🇳 Honduran", label: "🇭🇳 Honduran" },
    { value: "🇭🇺 Hungarian", label: "🇭🇺 Hungarian" },
    { value: "🇮🇸 Icelander", label: "🇮🇸 Icelander" },
    { value: "🇮🇳 Indian", label: "🇮🇳 Indian" },
    { value: "🇮🇩 Indonesian", label: "🇮🇩 Indonesian" },
    { value: "🇮🇷 Iranian", label: "🇮🇷 Iranian" },
    { value: "🇮🇶 Iraqi", label: "🇮🇶 Iraqi" },
    { value: "🇮🇪 Irish", label: "🇮🇪 Irish" },
    { value: "🇮🇱 Israeli", label: "🇮🇱 Israeli" },
    { value: "🇮🇹 Italian", label: "🇮🇹 Italian" },
    { value: "🇨🇮 Ivorian", label: "🇨🇮 Ivorian" },
    { value: "🇯🇲 Jamaican", label: "🇯🇲 Jamaican" },
    { value: "🇯🇵 Japanese", label: "🇯🇵 Japanese" },
    { value: "🇯🇴 Jordanian", label: "🇯🇴 Jordanian" },
    { value: "🇰🇿 Kazakhstani", label: "🇰🇿 Kazakhstani" },
    { value: "🇰🇪 Kenyan", label: "🇰🇪 Kenyan" },
    { value: "🇰🇮 Kiribati", label: "🇰🇮 Kiribati" },
    { value: "🇰🇵 North Korean", label: "🇰🇵 North Korean" },
    { value: "🇰🇷 South Korean", label: "🇰🇷 South Korean" },
    { value: "🇰🇼 Kuwaiti", label: "🇰🇼 Kuwaiti" },
    { value: "🇰🇬 Kyrgyzstani", label: "🇰🇬 Kyrgyzstani" },
    { value: "🇱🇦 Laotian", label: "🇱🇦 Laotian" },
    { value: "🇱🇻 Latvian", label: "🇱🇻 Latvian" },
    { value: "🇱🇧 Lebanese", label: "🇱🇧 Lebanese" },
    { value: "🇱🇸 Basotho", label: "🇱🇸 Basotho" },
    { value: "🇱🇷 Liberian", label: "🇱🇷 Liberian" },
    { value: "🇱🇾 Libyan", label: "🇱🇾 Libyan" },
    { value: "🇱🇹 Lithuanian", label: "🇱🇹 Lithuanian" },
    { value: "🇱🇺 Luxembourger", label: "🇱🇺 Luxembourger" },
    { value: "🇲🇬 Malagasy", label: "🇲🇬 Malagasy" },
    { value: "🇲🇼 Malawian", label: "🇲🇼 Malawian" },
    { value: "🇲🇾 Malaysian", label: "🇲🇾 Malaysian" },
    { value: "🇲🇻 Maldivian", label: "🇲🇻 Maldivian" },
    { value: "🇲🇱 Malian", label: "🇲🇱 Malian" },
    { value: "🇲🇹 Maltese", label: "🇲🇹 Maltese" },
    { value: "🇲🇭 Marshallese", label: "🇲🇭 Marshallese" },
    { value: "🇲🇷 Mauritanian", label: "🇲🇷 Mauritanian" },
    { value: "🇲🇺 Mauritian", label: "🇲🇺 Mauritian" },
    { value: "🇲🇽 Mexican", label: "🇲🇽 Mexican" },
    { value: "🇫🇲 Micronesian", label: "🇫🇲 Micronesian" },
    { value: "🇲🇩 Moldovan", label: "🇲🇩 Moldovan" },
    { value: "🇲🇨 Monacan", label: "🇲🇨 Monacan" },
    { value: "🇲🇳 Mongolian", label: "🇲🇳 Mongolian" },
    { value: "🇲🇪 Montenegrin", label: "🇲🇪 Montenegrin" },
    { value: "🇲🇦 Moroccan", label: "🇲🇦 Moroccan" },
    { value: "🇲🇿 Mozambican", label: "🇲🇿 Mozambican" },
    { value: "🇲🇲 Burmese", label: "🇲🇲 Burmese" },
    { value: "🇳🇦 Namibian", label: "🇳🇦 Namibian" },
    { value: "🇳🇷 Nauruan", label: "🇳🇷 Nauruan" },
    { value: "🇳🇵 Nepali", label: "🇳🇵 Nepali" },
    { value: "🇳🇱 Dutch", label: "🇳🇱 Dutch" },
    { value: "🇳🇿 New Zealander", label: "🇳🇿 New Zealander" },
    { value: "🇳🇮 Nicaraguan", label: "🇳🇮 Nicaraguan" },
    { value: "🇳🇪 Nigerien", label: "🇳🇪 Nigerien" },
    { value: "🇳🇬 Nigerian", label: "🇳🇬 Nigerian" },
    { value: "🇲🇰 North Macedonian", label: "🇲🇰 North Macedonian" },
    { value: "🇳🇴 Norwegian", label: "🇳🇴 Norwegian" },
    { value: "🇴🇲 Omani", label: "🇴🇲 Omani" },
    { value: "🇵🇰 Pakistani", label: "🇵🇰 Pakistani" },
    { value: "🇵🇼 Palauan", label: "🇵🇼 Palauan" },
    { value: "🇵🇸 Palestinian", label: "🇵🇸 Palestinian" },
    { value: "🇵🇦 Panamanian", label: "🇵🇦 Panamanian" },
    { value: "🇵🇬 Papua New Guinean", label: "🇵🇬 Papua New Guinean" },
    { value: "🇵🇾 Paraguayan", label: "🇵🇾 Paraguayan" },
    { value: "🇵🇪 Peruvian", label: "🇵🇪 Peruvian" },
    { value: "🇵🇭 Filipino", label: "🇵🇭 Filipino" },
    { value: "🇵🇱 Polish", label: "🇵🇱 Polish" },
    { value: "🇵🇹 Portuguese", label: "🇵🇹 Portuguese" },
    { value: "🇶🇦 Qatari", label: "🇶🇦 Qatari" },
    { value: "🇷🇴 Romanian", label: "🇷🇴 Romanian" },
    { value: "🇷🇺 Russian", label: "🇷🇺 Russian" },
    { value: "🇷🇼 Rwandan", label: "🇷🇼 Rwandan" },
    { value: "🇰🇳 Saint Kitts and Nevis", label: "🇰🇳 Saint Kitts and Nevis" },
    { value: "🇱🇨 Saint Lucian", label: "🇱🇨 Saint Lucian" },
    { value: "🇻🇨 Saint Vincentian", label: "🇻🇨 Saint Vincentian" },
    { value: "🇼🇸 Samoan", label: "🇼🇸 Samoan" },
    { value: "🇸🇲 Sanmarinese", label: "🇸🇲 Sanmarinese" },
    { value: "🇸🇦 Saudi", label: "🇸🇦 Saudi" },
    { value: "🇸🇳 Senegalese", label: "🇸🇳 Senegalese" },
    { value: "🇷🇸 Serbian", label: "🇷🇸 Serbian" },
    { value: "🇸🇨 Seychellois", label: "🇸🇨 Seychellois" },
    { value: "🇸🇱 Sierra Leonean", label: "🇸🇱 Sierra Leonean" },
    { value: "🇸🇬 Singaporean", label: "🇸🇬 Singaporean" },
    { value: "🇸🇰 Slovak", label: "🇸🇰 Slovak" },
    { value: "🇸🇮 Slovenian", label: "🇸🇮 Slovenian" },
    { value: "🇸🇧 Solomon Islander", label: "🇸🇧 Solomon Islander" },
    { value: "🇸🇴 Somali", label: "🇸🇴 Somali" },
    { value: "🇿🇦 South African", label: "🇿🇦 South African" },
    { value: "🇪🇸 Spanish", label: "🇪🇸 Spanish" },
    { value: "🇱🇰 Sri Lankan", label: "🇱🇰 Sri Lankan" },
    { value: "🇸🇩 Sudanese", label: "🇸🇩 Sudanese" },
    { value: "🇸🇷 Surinamese", label: "🇸🇷 Surinamese" },
    { value: "🇸🇿 Swazi", label: "🇸🇿 Swazi" },
    { value: "🇸🇪 Swedish", label: "🇸🇪 Swedish" },
    { value: "🇨🇭 Swiss", label: "🇨🇭 Swiss" },
    { value: "🇸🇾 Syrian", label: "🇸🇾 Syrian" },
    { value: "🇹🇼 Taiwanese", label: "🇹🇼 Taiwanese" },
    { value: "🇹🇯 Tajikistani", label: "🇹🇯 Tajikistani" },
    { value: "🇹🇿 Tanzanian", label: "🇹🇿 Tanzanian" },
    { value: "🇹🇭 Thai", label: "🇹🇭 Thai" },
    { value: "🇹🇱 Timorese", label: "🇹🇱 Timorese" },
    { value: "🇹🇬 Togolese", label: "🇹🇬 Togolese" },
    { value: "🇹🇴 Tongan", label: "🇹🇴 Tongan" },
    { value: "🇹🇹 Trinidadian", label: "🇹🇹 Trinidadian" },
    { value: "🇹🇳 Tunisian", label: "🇹🇳 Tunisian" },
    { value: "🇹🇷 Turkish", label: "🇹🇷 Turkish" },
    { value: "🇹🇲 Turkmen", label: "🇹🇲 Turkmen" },
    { value: "🇹🇻 Tuvaluan", label: "🇹🇻 Tuvaluan" },
    { value: "🇺🇬 Ugandan", label: "🇺🇬 Ugandan" },
    { value: "🇺🇦 Ukrainian", label: "🇺🇦 Ukrainian" },
    { value: "🇦🇪 Emirati", label: "🇦🇪 Emirati" },
    { value: "🇬🇧 British", label: "🇬🇧 British" },
    { value: "🇺🇸 American", label: "🇺🇸 American" },
    { value: "🇺🇾 Uruguayan", label: "🇺🇾 Uruguayan" },
    { value: "🇺🇿 Uzbekistani", label: "🇺🇿 Uzbekistani" },
    { value: "🇻🇺 Ni-Vanuatu", label: "🇻🇺 Ni-Vanuatu" },
    { value: "🇻🇦 Vatican", label: "🇻🇦 Vatican" },
    { value: "🇻🇪 Venezuelan", label: "🇻🇪 Venezuelan" },
    { value: "🇻🇳 Vietnamese", label: "🇻🇳 Vietnamese" },
    { value: "🇾🇪 Yemeni", label: "🇾🇪 Yemeni" },
    { value: "🇿🇲 Zambian", label: "🇿🇲 Zambian" },
    { value: "🇿🇼 Zimbabwean", label: "🇿🇼 Zimbabwean" },
];

const heightOptions = [
    { value: "4'6\"", label: "4'6\"" },
    { value: "4'7\"", label: "4'7\"" },
    { value: "4'8\"", label: "4'8\"" },
    { value: "4'9\"", label: "4'9\"" },
    { value: "5'0\"", label: "5'0\"" },
    { value: "5'1\"", label: "5'1\"" },
    { value: "5'2\"", label: "5'2\"" },
    { value: "5'3\"", label: "5'3\"" },
    { value: "5'4\"", label: "5'4\"" },
    { value: "5'5\"", label: "5'5\"" },
    { value: "5'6\"", label: "5'6\"" },
    { value: "5'7\"", label: "5'7\"" },
    { value: "5'8\"", label: "5'8\"" },
    { value: "5'9\"", label: "5'9\"" },
    { value: "5'10\"", label: "5'10\"" },
    { value: "5'11\"", label: "5'11\"" },
    { value: "6'0\"", label: "6'0\"" },
    { value: "6'1\"", label: "6'1\"" },
    { value: "6'2\"", label: "6'2\"" },
    { value: "6'3\"", label: "6'3\"" },
    { value: "6'4\"", label: "6'4\"" },
];

const educationLevelOptions = [
    { value: "🏫 High School", label: "🏫 High School" },
    { value: "🎓 High School Diploma", label: "🎓 High School Diploma" },
    { value: "📜 Associate's Degree", label: "📜 Associate's Degree" },
    { value: "🎓 Bachelor's Degree", label: "🎓 Bachelor's Degree" },
    { value: "🎓 Master's Degree", label: "🎓 Master's Degree" },
    { value: "🎓 Doctorate (PhD)", label: "🎓 Doctorate (PhD)" },
    { value: "👩‍🎓 Professional Degree", label: "👩‍🎓 Professional Degree" },
    { value: "🎓 Postdoctoral Studies", label: "🎓 Postdoctoral Studies" },
    { value: "🏫 Vocational Training", label: "🏫 Vocational Training" },
    { value: "🖥️ Online Courses", label: "🖥️ Online Courses" },
    { value: "🎨 Art School", label: "🎨 Art School" },
    { value: "🏫 Community College", label: "🏫 Community College" },
    { value: "📚 Continuing Education", label: "📚 Continuing Education" },
    { value: "👨‍🏫 Adult Education", label: "👨‍🏫 Adult Education" },
    { value: "📜 Certificate Program", label: "📜 Certificate Program" },
    { value: "📚 GED", label: "📚 GED" },
    { value: "👩‍🔬 Technical School", label: "👩‍🔬 Technical School" },
];

const professionOptions = [
    { value: "🏢 Still In School", label: "🏢 Still In School" },
    { value: "👨‍⚕️ Doctor", label: "👨‍⚕️ Doctor" },
    { value: "🦷 Dentist", label: "🦷 Dentist" },
    { value: "👮 Police Officer", label: "👮 Police Officer" },
    { value: "🕵️ Detective", label: "🕵️ Detective" },
    { value: "🚚 Truck Driver", label: "🚚 Truck Driver" },
    { value: "💻 Software Engineer", label: "💻 Software Engineer" },
    { value: "💻 Programmer/Analyst", label: "💻 Programmer/Analyst" },
    { value: "📊 Data Engineer", label: "📊 Data Engineer" },
    { value: "☁️ Cloud Engineer", label: "☁️ Cloud Engineer" },
    { value: "📈 Data Scientist", label: "📈 Data Scientist" },
    { value: "📊 Business Analyst", label: "📊 Business Analyst" },
    { value: "⚕️ Nurse", label: "⚕️ Nurse" },
    { value: "👨‍🍳 Chef", label: "👨‍🍳 Chef" },
    { value: "🔧 Automotive Mechanic", label: "🔧 Automotive Mechanic" },
    { value: "🧑‍🏫 Teacher", label: "🧑‍🏫 Teacher" },
    { value: "🧑‍🔬 Biologist", label: "🧑‍🔬 Biologist" },
    { value: "🔬 Chemist", label: "🔬 Chemist" },
    { value: "⚖️ Lawyer", label: "⚖️ Lawyer" },
    { value: "💉 Pharmacist", label: "💉 Pharmacist" },
    { value: "👩‍🎤 Actor", label: "👩‍🎤 Actor" },
    { value: "🎤 Singer", label: "🎤 Singer" },
    { value: "🎨 Artist", label: "🎨 Artist" },
    { value: "🏗️ Civil Engineer", label: "🏗️ Civil Engineer" },
    { value: "🧑‍💼 Accountant", label: "🧑‍💼 Accountant" },
    { value: "🏢 Business Consultant", label: "🏢 Business Consultant" },
    { value: "📊 Financial Analyst", label: "📊 Financial Analyst" },
    { value: "💼 Investment Banker", label: "💼 Investment Banker" },
    { value: "💡 Electrical Engineer", label: "💡 Electrical Engineer" },
    { value: "📋 Project Manager", label: "📋 Project Manager" },
    { value: "🧑‍💻 UX/UI Designer", label: "🧑‍💻 UX/UI Designer" },
    { value: "🔒 Cybersecurity Analyst", label: "🔒 Cybersecurity Analyst" },
    { value: "📝 Content Writer", label: "📝 Content Writer" },
    { value: "📞 Customer Service Representative", label: "📞 Customer Service Representative" },
    { value: "🚒 Firefighter", label: "🚒 Firefighter" },
    { value: "✈️ Pilot", label: "✈️ Pilot" },
    { value: "⚖️ Judge", label: "⚖️ Judge" },
    { value: "🖥️ IT Support Specialist", label: "🖥️ IT Support Specialist" },
    { value: "📱 App Developer", label: "📱 App Developer" },
    { value: "👩‍⚕️ Veterinarian", label: "👩‍⚕️ Veterinarian" },
    { value: "🛠️ Carpenter", label: "🛠️ Carpenter" },
    { value: "🛠️ Plumber", label: "🛠️ Plumber" },
    { value: "🎓 University Professor", label: "🎓 University Professor" },
    { value: "📊 Statistician", label: "📊 Statistician" },
    { value: "🧑‍💻 Web Developer", label: "🧑‍💻 Web Developer" },
    { value: "📸 Photographer", label: "📸 Photographer" },
    { value: "📈 Marketing Manager", label: "📈 Marketing Manager" },
    { value: "📦 Supply Chain Manager", label: "📦 Supply Chain Manager" },
    { value: "🧑‍💼 Human Resources Manager", label: "🧑‍💼 Human Resources Manager" },
    { value: "🖥️ Systems Administrator", label: "🖥️ Systems Administrator" },
    { value: "🚚 Delivery Driver", label: "🚚 Delivery Driver" },
    { value: "💻 Cloud Architect", label: "💻 Cloud Architect" },
    { value: "🔬 Research Scientist", label: "🔬 Research Scientist" },
    { value: "🧑‍⚕️ Psychologist", label: "🧑‍⚕️ Psychologist" },
    { value: "👷 Construction Manager", label: "👷 Construction Manager" },
    { value: "🔧 HVAC Technician", label: "🔧 HVAC Technician" },
    { value: "🎮 Game Developer", label: "🎮 Game Developer" },
    { value: "🎥 Film Director", label: "🎥 Film Director" },
    { value: "👨‍⚕️ Paramedic", label: "👨‍⚕️ Paramedic" },
    { value: "🏡 Real Estate Agent", label: "🏡 Real Estate Agent" },
    { value: "🧑‍⚖️ Paralegal", label: "🧑‍⚖️ Paralegal" },
    { value: "🖼️ Graphic Designer", label: "🖼️ Graphic Designer" },
    { value: "👨‍💼 CEO", label: "👨‍💼 CEO" },
    { value: "📋 Operations Manager", label: "📋 Operations Manager" },
    { value: "🛠️ Electrician", label: "🛠️ Electrician" },
    { value: "🚧 Construction Worker", label: "🚧 Construction Worker" },
    { value: "🏬 Retail Store Manager", label: "🏬 Retail Store Manager" },
    { value: "📱 Social Media Manager", label: "📱 Social Media Manager" },
    { value: "👷 Architect", label: "👷 Architect" },
    { value: "🦷 Orthodontist", label: "🦷 Orthodontist" },
    { value: "💰 Tax Consultant", label: "💰 Tax Consultant" },
    { value: "🚜 Agricultural Engineer", label: "🚜 Agricultural Engineer" },
    { value: "🛡️ Private Investigator", label: "🛡️ Private Investigator" },
    { value: "🎤 Radio Host", label: "🎤 Radio Host" },
    { value: "🚢 Ship Captain", label: "🚢 Ship Captain" },
    { value: "📋 Compliance Officer", label: "📋 Compliance Officer" },
    { value: "🎭 Theatre Director", label: "🎭 Theatre Director" },
    { value: "🎤 Voice Actor", label: "🎤 Voice Actor" },
    { value: "🎮 Video Game Tester", label: "🎮 Video Game Tester" },
    { value: "🔬 Forensic Scientist", label: "🔬 Forensic Scientist" },
    { value: "💼 Corporate Trainer", label: "💼 Corporate Trainer" },
    { value: "🖥️ DevOps Engineer", label: "🖥️ DevOps Engineer" },
    { value: "🏥 Hospital Administrator", label: "🏥 Hospital Administrator" },
    { value: "🔧 Industrial Engineer", label: "🔧 Industrial Engineer" },
    { value: "📋 Risk Analyst", label: "📋 Risk Analyst" },
    { value: "💻 Data Analyst", label: "💻 Data Analyst" },
    { value: "📞 Telemarketer", label: "📞 Telemarketer" },
    { value: "🚁 Helicopter Pilot", label: "🚁 Helicopter Pilot" },
    { value: "🎧 Sound Engineer", label: "🎧 Sound Engineer" },
    { value: "🔍 Intelligence Analyst", label: "🔍 Intelligence Analyst" },
    { value: "🎧 Music Producer", label: "🎧 Music Producer" },
    { value: "📞 Call Center Supervisor", label: "📞 Call Center Supervisor" },
    { value: "📋 Regulatory Affairs Specialist", label: "📋 Regulatory Affairs Specialist" },
    { value: "🎓 College Admissions Counselor", label: "🎓 College Admissions Counselor" },
    { value: "📱 Mobile App Developer", label: "📱 Mobile App Developer" },
    { value: "🚒 Fire Inspector", label: "🚒 Fire Inspector" },
    { value: "🎬 Film Editor", label: "🎬 Film Editor" },
    { value: "📋 Environmental Engineer", label: "📋 Environmental Engineer" },
    { value: "📋 Program Manager", label: "📋 Program Manager" },
    { value: "💉 Clinical Researcher", label: "💉 Clinical Researcher" },
    { value: "🎥 Cinematographer", label: "🎥 Cinematographer" },
    { value: "🎓 Academic Advisor", label: "🎓 Academic Advisor" },
    { value: "📚 Librarian", label: "📚 Librarian" },
    { value: "📋 Logistics Coordinator", label: "📋 Logistics Coordinator" },
    { value: "🎭 Actor/Actress", label: "🎭 Actor/Actress" },
    { value: "💆 Massage Therapist", label: "💆 Massage Therapist" },
    { value: "🔧 Marine Engineer", label: "🔧 Marine Engineer" },
    { value: "🛍️ Personal Shopper", label: "🛍️ Personal Shopper" },
    { value: "🔬 Medical Laboratory Technician", label: "🔬 Medical Laboratory Technician" },
    { value: "📞 IT Support Manager", label: "📞 IT Support Manager" },
    { value: "📝 Technical Writer", label: "📝 Technical Writer" },
    { value: "🎤 Public Relations Specialist", label: "🎤 Public Relations Specialist" },
    { value: "🧑‍🔬 Environmental Scientist", label: "🧑‍🔬 Environmental Scientist" },
    { value: "📦 Warehouse Manager", label: "📦 Warehouse Manager" },
    { value: "🎤 Podcaster", label: "🎤 Podcaster" },
    { value: "🩺 Radiologist", label: "🩺 Radiologist" },
    { value: "💉 Anesthesiologist", label: "💉 Anesthesiologist" },
    { value: "📊 SEO Specialist", label: "📊 SEO Specialist" },
    { value: "📝 Proofreader", label: "📝 Proofreader" },
    { value: "🖥️ Network Engineer", label: "🖥️ Network Engineer" },
    { value: "📡 Telecommunications Specialist", label: "📡 Telecommunications Specialist" },
    { value: "🎓 Education Coordinator", label: "🎓 Education Coordinator" },
    { value: "🚜 Farm Manager", label: "🚜 Farm Manager" },
    { value: "🛠️ Locksmith", label: "🛠️ Locksmith" },
    { value: "💼 Executive Assistant", label: "💼 Executive Assistant" },
    { value: "🎨 Fashion Designer", label: "🎨 Fashion Designer" },
    { value: "📋 Health and Safety Inspector", label: "📋 Health and Safety Inspector" },
    { value: "🎓 Curriculum Developer", label: "🎓 Curriculum Developer" },
    { value: "🎥 Stunt Performer", label: "🎥 Stunt Performer" },
    { value: "🚛 Heavy Equipment Operator", label: "🚛 Heavy Equipment Operator" },
    { value: "🚂 Train Conductor", label: "🚂 Train Conductor" },
    { value: "📦 Inventory Manager", label: "📦 Inventory Manager" },
    { value: "🛠️ Maintenance Worker", label: "🛠️ Maintenance Worker" },
    { value: "📚 Archivist", label: "📚 Archivist" },
    { value: "🚑 EMT (Emergency Medical Technician)", label: "🚑 EMT (Emergency Medical Technician)" },
    { value: "🏥 Medical Billing Specialist", label: "🏥 Medical Billing Specialist" },
    { value: "💻 Software Architect", label: "💻 Software Architect" },
    { value: "📞 Help Desk Technician", label: "📞 Help Desk Technician" },
    { value: "💡 Industrial Designer", label: "💡 Industrial Designer" },
    { value: "🛠️ Tool and Die Maker", label: "🛠️ Tool and Die Maker" },
    { value: "🖋️ Copywriter", label: "🖋️ Copywriter" },
    { value: "💻 Systems Analyst", label: "💻 Systems Analyst" },
    { value: "🔍 Compliance Analyst", label: "🔍 Compliance Analyst" },
    { value: "🚑 Ambulance Driver", label: "🚑 Ambulance Driver" },
    { value: "📋 Fundraising Manager", label: "📋 Fundraising Manager" },
    { value: "🎨 Interior Designer", label: "🎨 Interior Designer" },
    { value: "📊 Financial Planner", label: "📊 Financial Planner" },
    { value: "🚧 Safety Engineer", label: "🚧 Safety Engineer" },
    { value: "🔧 Robotics Engineer", label: "🔧 Robotics Engineer" },
    { value: "💡 Product Manager", label: "💡 Product Manager" },
    { value: "📦 Shipping and Receiving Clerk", label: "📦 Shipping and Receiving Clerk" },
    { value: "📋 Talent Acquisition Specialist", label: "📋 Talent Acquisition Specialist" },
    { value: "📈 Trader (Stock/Commodities)", label: "📈 Trader (Stock/Commodities)" },
    { value: "📊 Management Consultant", label: "📊 Management Consultant" },
    { value: "🔬 Biotechnologist", label: "🔬 Biotechnologist" },
    { value: "📝 Editor", label: "📝 Editor" },
    { value: "🏠 Building Inspector", label: "🏠 Building Inspector" },
    { value: "🧑‍🍳 Pastry Chef", label: "🧑‍🍳 Pastry Chef" },
    { value: "📊 Actuary", label: "📊 Actuary" },
    { value: "🔧 Mechanic", label: "🔧 Mechanic" },
    { value: "🔧 Aircraft Mechanic", label: "🔧 Aircraft Mechanic" },
    { value: "🚂 Locomotive Engineer", label: "🚂 Locomotive Engineer" },
    { value: "🔧 Machinist", label: "🔧 Machinist" },
    { value: "📋 Auditor", label: "📋 Auditor" },
    { value: "🎨 Multimedia Artist", label: "🎨 Multimedia Artist" },
    { value: "🔧 Diesel Mechanic", label: "🔧 Diesel Mechanic" },
    { value: "🏢 Facilities Manager", label: "🏢 Facilities Manager" },
    { value: "📋 Economist", label: "📋 Economist" },
    { value: "🔬 Microbiologist", label: "🔬 Microbiologist" },
    { value: "🔧 Millwright", label: "🔧 Millwright" },
    { value: "📝 Legal Secretary", label: "📝 Legal Secretary" },
    { value: "📦 Freight Forwarder", label: "📦 Freight Forwarder" },
    { value: "📋 Compensation Analyst", label: "📋 Compensation Analyst" },
    { value: "📊 Portfolio Manager", label: "📊 Portfolio Manager" },
    { value: "🎥 Camera Operator", label: "🎥 Camera Operator" },
    { value: "🎤 Event Host", label: "🎤 Event Host" },
    { value: "🛠️ Pest Control Technician", label: "🛠️ Pest Control Technician" },
    { value: "📝 Court Reporter", label: "📝 Court Reporter" },
    { value: "📚 Historian", label: "📚 Historian" },
    { value: "📝 Public Policy Analyst", label: "📝 Public Policy Analyst" },
    { value: "🔬 Nanotechnologist", label: "🔬 Nanotechnologist" },
    { value: "🚜 Agricultural Inspector", label: "🚜 Agricultural Inspector" },
    { value: "📊 Operations Analyst", label: "📊 Operations Analyst" },
    { value: "💼 Real Estate Broker", label: "💼 Real Estate Broker" },
    { value: "🎨 Video Editor", label: "🎨 Video Editor" },
    { value: "🚗 Auto Detailer", label: "🚗 Auto Detailer" },
    { value: "🔧 Wind Turbine Technician", label: "🔧 Wind Turbine Technician" },
    { value: "📞 Dispatcher", label: "📞 Dispatcher" },
    { value: "🎭 Casting Director", label: "🎭 Casting Director" },
    { value: "🔧 Petroleum Engineer", label: "🔧 Petroleum Engineer" },
    { value: "💼 Management Analyst", label: "💼 Management Analyst" },
    { value: "🔌 Power Plant Operator", label: "🔌 Power Plant Operator" },
    { value: "🏫 Homeschool Teacher", label: "🏫 Homeschool Teacher" },
    { value: "📦 Purchasing Manager", label: "📦 Purchasing Manager" },
    { value: "🛠️ Roofer", label: "🛠️ Roofer" },
    { value: "📊 Surveyor", label: "📊 Surveyor" },
    { value: "🔧 Pipefitter", label: "🔧 Pipefitter" },
    { value: "🏛️ Ambassador", label: "🏛️ Ambassador" },
    { value: "📞 Collections Specialist", label: "📞 Collections Specialist" },
    { value: "🎮 Game Designer", label: "🎮 Game Designer" },
    { value: "💻 Database Administrator", label: "💻 Database Administrator" },
    { value: "📝 Medical Transcriptionist", label: "📝 Medical Transcriptionist" },
    { value: "📝 Grant Writer", label: "📝 Grant Writer" },
    { value: "💼 Brand Manager", label: "💼 Brand Manager" },
    { value: "🎨 Sculptor", label: "🎨 Sculptor" },
    { value: "💼 Logistics Manager", label: "💼 Logistics Manager" },
    { value: "📚 Bookstore Manager", label: "📚 Bookstore Manager" },
    { value: "🧑‍💼 Executive Coach", label: "🧑‍💼 Executive Coach" },
    { value: "🎓 Educational Consultant", label: "🎓 Educational Consultant" },
    { value: "💼 Business Development Manager", label: "💼 Business Development Manager" },
    { value: "🚢 Ship Engineer", label: "🚢 Ship Engineer" },
    { value: "🔧 CNC Machine Operator", label: "🔧 CNC Machine Operator" },
    { value: "🎨 Tattoo Artist", label: "🎨 Tattoo Artist" },
    { value: "📋 Quality Assurance Analyst", label: "📋 Quality Assurance Analyst" },
    { value: "📞 Customer Support Specialist", label: "📞 Customer Support Specialist" },
    { value: "🎓 School Principal", label: "🎓 School Principal" },
    { value: "💼 Event Planner", label: "💼 Event Planner" },
    { value: "📞 Call Center Agent", label: "📞 Call Center Agent" },
    { value: "🔬 Physicist", label: "🔬 Physicist" },
    { value: "📞 Collections Agent", label: "📞 Collections Agent" },
    { value: "🎤 Media Buyer", label: "🎤 Media Buyer" },
    { value: "📊 Demographer", label: "📊 Demographer" },
    { value: "💻 Full Stack Developer", label: "💻 Full Stack Developer" },
    { value: "📋 Employee Relations Manager", label: "📋 Employee Relations Manager" },
    { value: "🎤 Speech Writer", label: "🎤 Speech Writer" },
    { value: "🔧 Appliance Repair Technician", label: "🔧 Appliance Repair Technician" },
    { value: "🎤 Talk Show Host", label: "🎤 Talk Show Host" },
    { value: "📋 Technical Support Engineer", label: "📋 Technical Support Engineer" },
    { value: "💻 Database Engineer", label: "💻 Database Engineer" },
    { value: "🔧 Calibration Technician", label: "🔧 Calibration Technician" },
    { value: "📞 Sales Coordinator", label: "📞 Sales Coordinator" },
    { value: "🔧 Sheet Metal Worker", label: "🔧 Sheet Metal Worker" },
    { value: "🚂 Train Engineer", label: "🚂 Train Engineer" },
    { value: "📋 Financial Controller", label: "📋 Financial Controller" },
    { value: "📞 Account Manager", label: "📞 Account Manager" },
    { value: "📞 Sales Representative", label: "📞 Sales Representative" },
    { value: "📞 Customer Success Manager", label: "📞 Customer Success Manager" },
    { value: "📝 Proposal Manager", label: "📝 Proposal Manager" },
    { value: "🔬 Virologist", label: "🔬 Virologist" },
    { value: "📋 Facilities Coordinator", label: "📋 Facilities Coordinator" },
    { value: "📞 Account Executive", label: "📞 Account Executive" },
    { value: "📋 Operations Coordinator", label: "📋 Operations Coordinator" },
    { value: "🖌️ Illustrator", label: "🖌️ Illustrator" },
    { value: "🖼️ Art Director", label: "🖼️ Art Director" },
    { value: "📱 Mobile Engineer", label: "📱 Mobile Engineer" },
    { value: "📦 Procurement Specialist", label: "📦 Procurement Specialist" },
    { value: "📋 Account Director", label: "📋 Account Director" },
    { value: "📊 Budget Analyst", label: "📊 Budget Analyst" },
    { value: "🎓 School Psychologist", label: "🎓 School Psychologist" },
    { value: "💻 Network Administrator", label: "💻 Network Administrator" },
    { value: "🛠️ Electrical Technician", label: "🛠️ Electrical Technician" },
    { value: "🖼️ Fine Arts Curator", label: "🖼️ Fine Arts Curator" },
    { value: "🛠️ Equipment Technician", label: "🛠️ Equipment Technician" },
    { value: "💻 Cloud Security Specialist", label: "💻 Cloud Security Specialist" },
    { value: "📊 Business Development Executive", label: "📊 Business Development Executive" },
    { value: "💼 Finance Manager", label: "💼 Finance Manager" },
    { value: "🔬 Biomedical Engineer", label: "🔬 Biomedical Engineer" },
    { value: "🛠️ Field Service Technician", label: "🛠️ Field Service Technician" },
    { value: "🖥️ Game Programmer", label: "🖥️ Game Programmer" },
    { value: "📋 HR Coordinator", label: "📋 HR Coordinator" },
    { value: "💻 IT Support Engineer", label: "💻 IT Support Engineer" },
    { value: "🎨 Landscape Architect", label: "🎨 Landscape Architect" },
    { value: "📊 Market Research Analyst", label: "📊 Market Research Analyst" },
    { value: "📋 Office Administrator", label: "📋 Office Administrator" },
    { value: "🔌 Power Systems Engineer", label: "🔌 Power Systems Engineer" },
    { value: "🖌️ Product Designer", label: "🖌️ Product Designer" },
    { value: "📦 Procurement Manager", label: "📦 Procurement Manager" },
    { value: "🎭 Scriptwriter", label: "🎭 Scriptwriter" },
    { value: "🎓 Student Counselor", label: "🎓 Student Counselor" },
    { value: "📞 Technical Recruiter", label: "📞 Technical Recruiter" },
    { value: "🎤 TV Host", label: "🎤 TV Host" },
    { value: "💻 UI/UX Developer", label: "💻 UI/UX Developer" },
    { value: "🖌️ Visual Merchandiser", label: "🖌️ Visual Merchandiser" },
    { value: "📋 Warehouse Supervisor", label: "📋 Warehouse Supervisor" },
    { value: "🧑‍⚕️ Audiology Specialist", label: "🧑‍⚕️ Audiology Specialist" },
    { value: "📊 Credit Analyst", label: "📊 Credit Analyst" },
    { value: "🎓 Career Advisor", label: "🎓 Career Advisor" },
    { value: "📈 Investment Analyst", label: "📈 Investment Analyst" },
    { value: "📋 Legal Consultant", label: "📋 Legal Consultant" },
    { value: "🔌 Electronics Engineer", label: "🔌 Electronics Engineer" },
    { value: "🧑‍🏫 ESL Teacher", label: "🧑‍🏫 ESL Teacher" },
    { value: "📦 Logistics Analyst", label: "📦 Logistics Analyst" },
    { value: "📋 Payroll Manager", label: "📋 Payroll Manager" },
    { value: "🖌️ Art Conservator", label: "🖌️ Art Conservator" },
    { value: "📊 Business Operations Manager", label: "📊 Business Operations Manager" },
    { value: "🎤 Music Composer", label: "🎤 Music Composer" },
    { value: "📋 Regulatory Compliance Officer", label: "📋 Regulatory Compliance Officer" },
    { value: "🔧 Maintenance Technician", label: "🔧 Maintenance Technician" },
    { value: "📋 Policy Analyst", label: "📋 Policy Analyst" },
    { value: "🛠️ Equipment Operator", label: "🛠️ Equipment Operator" },
    { value: "📋 Industrial Designer", label: "📋 Industrial Designer" },
    { value: "🔧 Marine Technician", label: "🔧 Marine Technician" },
    { value: "🎨 Motion Graphics Designer", label: "🎨 Motion Graphics Designer" },
    { value: "📊 Quantitative Analyst", label: "📊 Quantitative Analyst" },
    { value: "📋 Records Manager", label: "📋 Records Manager" },
    { value: "🔌 Renewable Energy Engineer", label: "🔌 Renewable Energy Engineer" },
    { value: "🔬 Research Chemist", label: "🔬 Research Chemist" },
    { value: "📋 Safety Coordinator", label: "📋 Safety Coordinator" },
    { value: "🔧 Service Technician", label: "🔧 Service Technician" },
    { value: "🎓 Training Specialist", label: "🎓 Training Specialist" },
    { value: "🛠️ Welding Inspector", label: "🛠️ Welding Inspector" },
    { value: "📋 Workforce Planner", label: "📋 Workforce Planner" },
    { value: "💼 Content Strategist", label: "💼 Content Strategist" },
    { value: "🔧 HVAC Installer", label: "🔧 HVAC Installer" },
    { value: "📞 Bilingual Customer Support", label: "📞 Bilingual Customer Support" },
    { value: "🎤 Public Speaker", label: "🎤 Public Speaker" },
    { value: "🖌️ Print Designer", label: "🖌️ Print Designer" },
    { value: "📈 Tax Auditor", label: "📈 Tax Auditor" },
    { value: "🎤 Event Coordinator", label: "🎤 Event Coordinator" },
    { value: "📊 Supply Chain Analyst", label: "📊 Supply Chain Analyst" },
    { value: "🖥️ Virtual Reality Developer", label: "🖥️ Virtual Reality Developer" },
    { value: "📋 Data Governance Analyst", label: "📋 Data Governance Analyst" },
    { value: "📊 Pricing Analyst", label: "📊 Pricing Analyst" },
    { value: "🎤 Communications Specialist", label: "🎤 Communications Specialist" },
    { value: "📋 Quality Manager", label: "📋 Quality Manager" },
    { value: "🎨 Set Designer", label: "🎨 Set Designer" },
    { value: "📋 Contract Manager", label: "📋 Contract Manager" },
    { value: "📞 Outreach Coordinator", label: "📞 Outreach Coordinator" },
    { value: "🔧 Turbine Mechanic", label: "🔧 Turbine Mechanic" },
    { value: "📋 Facilities Director", label: "📋 Facilities Director" },
    { value: "🎤 Media Specialist", label: "🎤 Media Specialist" },
    { value: "📋 Account Coordinator", label: "📋 Account Coordinator" },
    { value: "📊 Workforce Analyst", label: "📊 Workforce Analyst" },
    { value: "🎓 Graduate Advisor", label: "🎓 Graduate Advisor" },
    { value: "📋 Process Improvement Manager", label: "📋 Process Improvement Manager" },
    { value: "📞 Talent Scout", label: "📞 Talent Scout" },
    { value: "📊 Market Intelligence Specialist", label: "📊 Market Intelligence Specialist" },
    { value: "📋 Technical Account Manager", label: "📋 Technical Account Manager" },
    { value: "🖥️ Infrastructure Engineer", label: "🖥️ Infrastructure Engineer" },
    { value: "📊 Big Data Consultant", label: "📊 Big Data Consultant" },
    { value: "🔌 Electrical Supervisor", label: "🔌 Electrical Supervisor" },
    { value: "📋 Portfolio Administrator", label: "📋 Portfolio Administrator" },
    { value: "🎓 Educational Psychologist", label: "🎓 Educational Psychologist" },
    { value: "📋 Digital Transformation Manager", label: "📋 Digital Transformation Manager" },
    { value: "🛠️ Robotics Technician", label: "🛠️ Robotics Technician" },
    { value: "📞 Operations Specialist", label: "📞 Operations Specialist" },
    { value: "📊 Digital Analyst", label: "📊 Digital Analyst" },
    { value: "🎤 Voice-over Artist", label: "🎤 Voice-over Artist" },
    { value: "📋 Organizational Development Specialist", label: "📋 Organizational Development Specialist" },
    { value: "📞 Community Relations Manager", label: "📞 Community Relations Manager" },
    { value: "📋 Innovation Manager", label: "📋 Innovation Manager" },
    { value: "🛠️ Industrial Safety Technician", label: "🛠️ Industrial Safety Technician" },
    { value: "🖌️ Concept Artist", label: "🖌️ Concept Artist" },
    { value: "📋 Event Logistics Coordinator", label: "📋 Event Logistics Coordinator" },
    { value: "🔧 Power Tool Technician", label: "🔧 Power Tool Technician" },
    { value: "📋 Urban Planner", label: "📋 Urban Planner" },
    { value: "📊 Business Process Analyst", label: "📊 Business Process Analyst" },
    { value: "🎤 Entertainment Manager", label: "🎤 Entertainment Manager" },
    { value: "📋 Compliance Specialist", label: "📋 Compliance Specialist" },
    { value: "📊 Insurance Underwriter", label: "📊 Insurance Underwriter" },
    { value: "🎨 Jewelry Designer", label: "🎨 Jewelry Designer" },
    { value: "🖥️ E-commerce Specialist", label: "🖥️ E-commerce Specialist" },
    { value: "📊 Operations Manager", label: "📊 Operations Manager" },
    { value: "🔧 Quality Control Technician", label: "🔧 Quality Control Technician" },
    { value: "🖌️ Motion Capture Artist", label: "🖌️ Motion Capture Artist" },
    { value: "📊 Cyber Threat Analyst", label: "📊 Cyber Threat Analyst" },
    { value: "🔧 Pipeline Engineer", label: "🔧 Pipeline Engineer" },
    { value: "📋 Resource Planner", label: "📋 Resource Planner" },
    { value: "🎤 Storyboard Artist", label: "🎤 Storyboard Artist" },
    { value: "📞 Client Relationship Manager", label: "📞 Client Relationship Manager" },
    { value: "🖥️ Systems Designer", label: "🖥️ Systems Designer" },
    { value: "📋 Corporate Social Responsibility Manager", label: "📋 Corporate Social Responsibility Manager" },
    { value: "📊 Economic Development Specialist", label: "📊 Economic Development Specialist" },
    { value: "📋 Service Delivery Manager", label: "📋 Service Delivery Manager" },
    { value: "📞 Membership Coordinator", label: "📞 Membership Coordinator" },
    { value: "📊 Business Intelligence Developer", label: "📊 Business Intelligence Developer" },
    { value: "📋 Data Privacy Consultant", label: "📋 Data Privacy Consultant" },
    { value: "🎓 Learning and Development Specialist", label: "🎓 Learning and Development Specialist" },
    { value: "🎤 Voice Acting Trainer", label: "🎤 Voice Acting Trainer" },
    { value: "📋 Digital Media Buyer", label: "📋 Digital Media Buyer" },
    { value: "🎨 Animation Director", label: "🎨 Animation Director" },
    { value: "🔧 Instrumentation Engineer", label: "🔧 Instrumentation Engineer" },
    { value: "📋 Procurement Officer", label: "📋 Procurement Officer" },
    { value: "📋 Event Operations Manager", label: "📋 Event Operations Manager" },
    { value: "📞 Campaign Specialist", label: "📞 Campaign Specialist" },
    { value: "📋 Technical Sales Engineer", label: "📋 Technical Sales Engineer" },
    { value: "📊 Workforce Development Manager", label: "📊 Workforce Development Manager" },
    { value: "🎤 Speech Therapist", label: "🎤 Speech Therapist" },
    { value: "🔧 Aircraft Technician", label: "🔧 Aircraft Technician" },
    { value: "📋 Budget Controller", label: "📋 Budget Controller" },
    { value: "📋 Compensation Specialist", label: "📋 Compensation Specialist" },
    { value: "📋 Humanitarian Aid Worker", label: "📋 Humanitarian Aid Worker" },
    { value: "🔬 Environmental Health Specialist", label: "🔬 Environmental Health Specialist" },
    { value: "🎓 Education Program Manager", label: "🎓 Education Program Manager" },
    { value: "📋 Operations Supervisor", label: "📋 Operations Supervisor" },
    { value: "📊 Risk Management Specialist", label: "📊 Risk Management Specialist" },
    { value: "📋 Employee Engagement Coordinator", label: "📋 Employee Engagement Coordinator" },
    { value: "🎤 Motivational Speaker", label: "🎤 Motivational Speaker" },
    { value: "📋 Data Migration Specialist", label: "📋 Data Migration Specialist" },
    { value: "🔧 Heavy Machinery Operator", label: "🔧 Heavy Machinery Operator" },
    { value: "📋 Learning Designer", label: "📋 Learning Designer" },
    { value: "📋 Freelance Photographer", label: "📋 Freelance Photographer" },
    { value: "📊 Retail Analyst", label: "📊 Retail Analyst" },
    { value: "🔧 Automotive Electrician", label: "🔧 Automotive Electrician" },
    { value: "📋 Inventory Planner", label: "📋 Inventory Planner" },
    { value: "🔬 Material Scientist", label: "🔬 Material Scientist" },
    { value: "📋 Creative Project Manager", label: "📋 Creative Project Manager" },
    { value: "🎓 Special Education Teacher", label: "🎓 Special Education Teacher" },
    { value: "🎤 Voiceover Artist", label: "🎤 Voiceover Artist" },
    { value: "🔬 Clinical Scientist", label: "🔬 Clinical Scientist" },
    { value: "🔧 Automotive Technician", label: "🔧 Automotive Technician" },
    { value: "🎓 Career Transition Specialist", label: "🎓 Career Transition Specialist" },
    { value: "📊 Analytics Consultant", label: "📊 Analytics Consultant" },
    { value: "📋 Customer Experience Manager", label: "📋 Customer Experience Manager" },
    { value: "📋 Customer Success Consultant", label: "📋 Customer Success Consultant" },
    { value: "📋 Aviation Engineer", label: "📋 Aviation Engineer" },
    { value: "📋 Brand Identity Designer", label: "📋 Brand Identity Designer" },
    { value: "📋 Marketing Automation Specialist", label: "📋 Marketing Automation Specialist" },
    { value: "📋 Systems Operator", label: "📋 Systems Operator" },
    { value: "📋 Logistics Assistant", label: "📋 Logistics Assistant" },
    { value: "🔬 Quality Assurance Chemist", label: "🔬 Quality Assurance Chemist" },
    { value: "🔧 Railway Engineer", label: "🔧 Railway Engineer" },
    { value: "📋 Contracts Negotiator", label: "📋 Contracts Negotiator" },
    { value: "📋 Recruitment Consultant", label: "📋 Recruitment Consultant" },
    { value: "📋 Volunteer Coordinator", label: "📋 Volunteer Coordinator" },
    { value: "🔬 Laboratory Manager", label: "🔬 Laboratory Manager" },
    { value: "🎤 Drama Therapist", label: "🎤 Drama Therapist" },
    { value: "🔬 Petroleum Geologist", label: "🔬 Petroleum Geologist" },
    { value: "📋 Disaster Response Coordinator", label: "📋 Disaster Response Coordinator" },
    { value: "📋 Partnership Manager", label: "📋 Partnership Manager" },
];

const styles = {
    topMenuArea: {
        backgroundColor: "#f7f7f7",
        padding: "15px 0",
    },
    container: {
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "0 15px",
    },
    row: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "calc(100vh - 150px)", // Adjust height for better centering
        marginBottom: "50px",
    },
    logRegInner: {
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "10px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        width: "100%",
        maxWidth: "700px",
        textAlign: "center",
    },
    sectionHeader: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "28px",
        fontWeight: "700",
        margin: "0 0 10px",
        color: "#333",
    },
    description: {
        fontSize: "16px",
        color: "#666",
    },
    mainContent: {
        marginTop: "20px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    formGroup: {
        marginBottom: "20px",
    },
    label: {
        display: "block",
        marginBottom: "8px",
        fontSize: "14px",
        fontWeight: "600",
        color: "#333",
        textAlign: "left",
    },
    input: {
        width: "100%",
        padding: "10px",
        borderRadius: "6px",
        border: "1px solid #ccc",
        fontSize: "14px",
        color: "#333",
    },
    select: {
        width: "100%",
        padding: "10px",
        borderRadius: "6px",
        border: "1px solid #ccc",
        fontSize: "14px",
        color: "#333",
    },
    submitButton: {
        backgroundColor: "#007bff",
        color: "#fff",
        padding: "12px 20px",
        border: "none",
        borderRadius: "6px",
        fontSize: "16px",
        cursor: "pointer",
        marginTop: "20px",
    },
    error: {
        color: "red",
        fontSize: "12px",
    },
};



const CreateCandidate = () => {
    const [formData, setFormData] = useState({
        name: '',
        age: '',
        gender: '',
        bio: '',
        avatar: '',
        userStatus: 'Offline',
        dob: '1999/01/01',
        username: '',
        deviceToken: '',
        height: '',
        countryGrown: '',
        educationLevel: '',
        profession: '',
        familySituation: '',
        liveWith: '',
        children: '',
        wantChildren: '',
        onPlatform: '',
        moveAbroad: '',
        religious: '',
        smoker: '',
        drinker: '',
        eatHalal: '',
        practiceSalat: '',
        muslimSector: '',
        locationLongitude: '-79.347015',
        locationLatitude: '43.651070',
        city: '',
        country: '',
        hajjUmrah: '',
        verified: '', // Ensure this matches the database
        birthYear: '', // Set a default value
        isLiveInQueue: false,
        isInMeeting: false,
        matchmakerID: '',
        isPremiumUser: false,
        likesRemaining: 0,
        messagesRemaining: 0,
        liveMatchesRemaining: 0,
        preferences: '[{"selected":true,"id":"9C7119FF-C236-4FD5-8E73-B33183E6CB71","preferenceColorHex":"0A84FF","preferenceId":"A","preferenceName":"🥾 Hiking"},{"preferenceColorHex":"FFD60A","id":"161CCB8B-E1D4-4054-AAFD-25848CFFAC05","preferenceName":"🍔 Food","preferenceId":"B","selected":true},{"preferenceId":"D","preferenceName":"🐱 Cat lover","id":"9D980534-C9FB-4A8E-8A77-E53536960172","selected":true,"preferenceColorHex":"BF5AF2"}]',
        personalities: '[{"selected":true,"personalityName":"💼 Business-minded","id":"D4BAB6C2-2C5A-4D87-A79A-E574832E7185","personalityColorHex":"8E8E93","personalityId":"DG"},{"personalityName":"🕊️ Peaceful","personalityId":"DR","id":"89CAE5F7-3D78-43E0-A57C-5F9F9ABF5295","personalityColorHex":"0A84FF","selected":true},{"selected":true,"personalityName":"🎵 Musical","personalityColorHex":"FF375F","personalityId":"EF","id":"754A22D3-4C74-40DF-9DA2-C80ACAAD7264"},{"personalityId":"EE","personalityName":"💬 Communicative","personalityColorHex":"BF5AF2","selected":true,"id":"92DDF7FB-3980-480F-8801-B5DBB953EEB0"},{"personalityColorHex":"8E8E93","id":"60544F47-574D-4692-9FE3-26240393A4FA","personalityName":"🎓 Educated","selected":true,"personalityId":"FG"}]',
        isPicture1Blur: false,
        isPicture2Blur: false,
    });


    const [errorMessages, setErrorMessages] = useState({});
    const [redirectToHome, setRedirectToHome] = useState(false);

    const { matchMakerId } = useParams(); // Get matchMakerId from URL params
    const navigate = useNavigate(); // Get navigate function

    const [imageSrc, setImageSrc] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const [currentField, setCurrentField] = useState('');
    const cropperRef = useRef(null);

    const handleFileChange = (e, field, username) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result); // Show image in cropper
                setCurrentField(field); // Track which image is being cropped
                setShowCropper(true); // Display the cropper
            };
            reader.readAsDataURL(file);
        } else {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [field === '1' ? 'avatar' : `image${field}`]: `${field === '1' ? 'Profile picture' : `Image ${field}`} is required.`,
            }));
        }
    };

    // Function to handle uploading the cropped image
    const handleImageUpload = async (field, username) => {
        try {
            // Get the cropped image data URL
            const croppedDataUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
    
            // Convert base64 to a Blob object
            const blob = await fetch(croppedDataUrl).then((res) => res.blob());
    
            // Compress the image blob
            const compressedBlob = await imageCompression(blob, {
                maxSizeMB: 0.8,  // Max size in MB (adjust as needed)
                maxWidthOrHeight: 800,  // Resize image to a max width/height (adjust as needed)
                useWebWorker: true,
            });
    
            // Convert the compressed blob to a File object
            const file = new File([compressedBlob], `${username}-${field}.jpg`, { type: "image/jpeg" });
    
            // Proceed with the upload process
            const uploadTask = uploadData({
                path: `public/${username}/${field}`,
                data: file,
                options: {
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        if (totalBytes) {
                            console.log(
                                `Upload progress for ${field}: ${Math.round((transferredBytes / totalBytes) * 100)}%`
                            );
                        }
                    },
                },
            });
    
            const result = await uploadTask.result;
            console.log(`File uploaded successfully: ${result.path}`);
            const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
    
            setFormData((prevData) => ({
                ...prevData,
                [field === '1' ? 'avatar' : `image${field}`]: `${S3_BUCKET_URL}/${result.path}`,
            }));
    
            setShowCropper(false); // Hide cropper after upload
            setImageSrc(null);
            setCurrentField(null);
        } catch (error) {
            console.error(`Error uploading ${field}:`, error);
        }
    };
    

    // Function to crop the selected image
    const handleCrop = (e) => {
        e.preventDefault(); // Prevent form submission
        if (cropperRef.current) {
            handleImageUpload(currentField, formData.username);
        }
    };




    const validateForm = () => {
        const requiredFields = [
            "name",
            "age",
            "gender",
            "bio",
            "avatar",
            "username",
            // "dob",
            "height",
            "countryGrown",
            "educationLevel",
            "profession",
            "city",
            "country",
            "familySituation", // NEW
            "liveWith", // NEW
            "children", // NEW
            "wantChildren", // NEW
            "onPlatform", // NEW
            "moveAbroad", // NEW
            "religious", // NEW
            "smoker", // NEW
            "drinker", // NEW
            "eatHalal", // NEW
            "practiceSalat", // NEW
            "muslimSector", // NEW
            "hajjUmrah", // NEW
        ];

        const errors = {};

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required.`;
            }
        });

        // Validate Date of Birth (1950–2007)
        // if (formData.dob) {
        //     const birthYear = parseInt(formData.dob.split("-")[0], 10);
        //     if (birthYear < 1950 || birthYear > 2007) {
        //         errors.dob = "Date of Birth must be between 1950 and 2007.";
        //     }
        // }

        // Validate Image Fields
        if (!formData.avatar || formData.avatar.trim() === "") {
            errors.avatar = "Profile picture is required.";
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("Submitting form..."); // Debugging: Log before validation
        if (!validateForm()) {
            console.log("Validation failed.");
            return; // Stop submission if validation fails
        }

        try {
            const allowedFields = [
                'name',
                'age',
                'gender',
                'bio',
                'avatar',
                'dob',
                'username',
                'locationLongitude',
                'locationLatitude',
                'userStatus',
                'deviceToken',
                'height',
                'countryGrown',
                'educationLevel',
                'profession',
                'familySituation',
                'liveWith',
                'children',
                'wantChildren',
                'onPlatform',
                'moveAbroad',
                'religious',
                'smoker',
                'drinker',
                'eatHalal',
                'practiceSalat',
                'muslimSector',
                'city',
                'country',
                'hajjUmrah',
                'verified',
                'birthYear',
                'isLiveInQueue',
                'isInMeeting',
                'matchmakerID',
                'isPremiumUser',
                'likesRemaining',
                'messagesRemaining',
                'liveMatchesRemaining',
                'preferences',
                'personalities',
                'isPicture1Blur',
                'isPicture2Blur',
            ];

            const currentYear = new Date().getFullYear();
            const birthYear = formData.age ? currentYear - parseInt(formData.age, 10) : null;

            const age = birthYear ? new Date().getFullYear() - birthYear : null;

            const filteredData = Object.keys(formData)
                .filter((key) => allowedFields.includes(key))
                .reduce((obj, key) => {
                    obj[key] = formData[key];
                    return obj;
                }, {});

            const input = {
                ...filteredData,
                matchmakerID: matchMakerId,
                birthYear,
                age,
            };

            console.log("Submitting data:", input); // Debugging: Log submitted data

            const result = await client.graphql({
                query: createUser,
                variables: { input },
            });

            console.log("Candidate created successfully:", result);
            setRedirectToHome(true);
        } catch (error) {
            console.error("Error creating candidate:", error.message || error);
            setErrorMessages({ general: "An error occurred while creating the candidate." });
        }
    };



    const handleChange = (selectedOption, stateKey) => {
        setFormData({ ...formData, [stateKey]: selectedOption.value });
    };

    if (redirectToHome) {
        return <Navigate to={`/matchmaker-home/${matchMakerId}`} />;
    }

    const title = "Create Candidate Profile";
    const desc = "Fill in the details below to create a profile for your candidate.";
    const accTitle = "Profile Details";

    const renderFormGroup = (label, name, value, placeholder, type = "text") => (
        <div style={styles.formGroup}>
            <label style={styles.label}>{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                style={styles.input}
                placeholder={placeholder}
            />
            {errorMessages[name] && <p className="error-message">{errorMessages[name]}</p>}
        </div>
    );

    if (redirectToHome) {
        return <Navigate to={`/matchmaker-home/${matchMakerId}`} />;
    }

    const renderSelect = (label, name, value, options) => (
        <div style={styles.formGroup}>
            <label style={styles.label}>{label}</label>
            <Select
                value={{ label: value, value }}
                onChange={(selectedOption) =>
                    setFormData({ ...formData, [name]: selectedOption.value })
                }
                options={options}
                styles={{
                    control: (base) => ({
                        ...base,
                        padding: "6px",
                        fontSize: "14px",
                    }),
                }}
            />
        </div>
    );

    const renderFileInput = (label, index, username) => (
        <div style={styles.formGroup}>
            <label style={styles.label}>{label}</label>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, index, username)}
                style={styles.input}
            />
            {errorMessages[index === '1' ? 'avatar' : `image${index}`] && (
                <p className="error-message">{errorMessages[index === '1' ? 'avatar' : `image${index}`]}</p>
            )}

            {formData[index === '1' ? 'avatar' : `image${index}`] && (
                <img
                    src={formData[index === '1' ? 'avatar' : `image${index}`]}
                    alt={`Uploaded ${index}`}
                    style={{ maxWidth: "200px", marginTop: "10px" }}
                />
            )}

            {imageSrc && currentField === index && (
                <div style={{ marginTop: "20px" }}>
                    <h3>Crop Image</h3>
                    <Cropper
                        src={imageSrc}
                        style={{ height: 400, width: "100%" }}
                        initialAspectRatio={1}
                        guides={true}
                        ref={cropperRef}
                        viewMode={1}
                        zoomable={true}
                    />
                    <button onClick={handleCrop} style={{ marginTop: "10px" }}>
                        Crop & Save
                    </button>
                </div>
            )}
        </div>
    );


    return (
        <section className="log-reg">
            <div
                className="bred_crumb"
                style={{
                    backgroundImage: `url(${BGImg})`,
                    padding: "50px 0",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
            <div style={{ textAlign: "left", margin: "40px 0 20px 360px" }}>
                <Link to={`/matchmaker-home/${matchMakerId}`} style={{ color: "#007bff", fontSize: "16px", textDecoration: "none" }}>
                    &larr; Back to Matchmaker Home
                </Link>
            </div>

            <div className="container" style={styles.container}>
                <div className="row" style={styles.row}>
                    <div className="log-reg-inner" style={styles.logRegInner}>
                        <div className="section-header" style={styles.sectionHeader}>
                            <h2 className="title" style={styles.title}>{title}</h2>
                            <p style={styles.description}>{desc}</p>
                        </div>
                        <div className="main-content" style={styles.mainContent}>
                            <form onSubmit={handleSubmit} style={styles.form}>
                                {renderFormGroup("Full Name*", "name", formData.name, "Enter Your Full Name")}
                                {renderFormGroup("Age*", "age", formData.age, "Enter Your Age", "number")}
                                {renderFormGroup("Username*", "username", formData.username, "Enter a username")}
                                {renderSelect("Gender*", "gender", formData.gender, [
                                    { value: "Male 👨", label: "Male 👨" },
                                    { value: "Female 👩", label: "Female 👩" },
                                ])}
                                {errorMessages["gender"] && <p style={{ color: "red" }}>{errorMessages["gender"]}</p>}
                                {renderFormGroup("Bio*", "bio", formData.bio, "Enter Your Bio")}
                                {renderSelect("Height*", "height", formData.height, heightOptions)}
                                {errorMessages["height"] && <p style={{ color: "red" }}>{errorMessages["height"]}</p>}
                                {renderSelect("Ethnicity*", "countryGrown", formData.countryGrown, countryGrownOptions)}
                                {errorMessages["countryGrown"] && <p style={{ color: "red" }}>{errorMessages["countryGrown"]}</p>}
                                {renderSelect("Education Level*", "educationLevel", formData.educationLevel, educationLevelOptions)}
                                {errorMessages["educationLevel"] && <p style={{ color: "red" }}>{errorMessages["educationLevel"]}</p>}
                                {renderSelect("Profession*", "profession", formData.profession, professionOptions)}
                                {errorMessages["profession"] && <p style={{ color: "red" }}>{errorMessages["profession"]}</p>}
                                {renderFormGroup("City*", "city", formData.city, "Enter Your City")}
                                {renderFormGroup("Country*", "country", formData.country, "Enter Your Country")}
                                {renderSelect("My Family Situation*", "familySituation", formData.familySituation, [
                                    { value: "💍 Married", label: "💍 Married" },
                                    { value: "❤️ Single", label: "❤️ Single" },
                                    { value: "💔 Divorced", label: "💔 Divorced" },
                                    { value: "🖤 Widowed", label: "🖤 Widowed" },
                                    { value: "🔄 Annulled", label: "🔄 Annulled" },
                                ])}
                                {errorMessages["familySituation"] && <p style={{ color: "red" }}>{errorMessages["familySituation"]}</p>}

                                {renderSelect("Who do you live with?", "liveWith", formData.liveWith, [
                                    { value: "🏡 Live alone", label: "🏡 Live alone" },
                                    { value: "👪 Live with family", label: "👪 Live with family" },
                                    { value: "👫 Live with roommates", label: "👫 Live with roommates" },
                                ])}
                                {errorMessages["liveWith"] && <p style={{ color: "red" }}>{errorMessages["liveWith"]}</p>}

                                {renderSelect("Do you have any children?", "children", formData.children, [
                                    { value: "👶 A few children", label: "👶 A few children" },
                                    { value: "🚫 Don't have children", label: "🚫 Don't have children" },
                                ])}
                                {errorMessages["children"] && <p style={{ color: "red" }}>{errorMessages["children"]}</p>}

                                {renderSelect("Do you want children in the future?", "wantChildren", formData.wantChildren, [
                                    { value: "👶 I want children", label: "👶 I want children" },
                                    { value: "🚫 I don't want children", label: "🚫 I don't want children" },
                                ])}
                                {errorMessages["wantChildren"] && <p style={{ color: "red" }}>{errorMessages["wantChildren"]}</p>}

                                {renderSelect("Why are you on this platform?", "onPlatform", formData.onPlatform, [
                                    { value: "💍 Looking to get married", label: "💍 Looking to get married" },
                                    { value: "👀 Just exploring", label: "👀 Just exploring" },
                                ])}
                                {errorMessages["onPlatform"] && <p style={{ color: "red" }}>{errorMessages["onPlatform"]}</p>}

                                {renderSelect("Are you open to moving abroad?", "moveAbroad", formData.moveAbroad, [
                                    { value: "✈️ Open to moving abroad", label: "✈️ Open to moving abroad" },
                                    { value: "🏡 Not open to moving abroad", label: "🏡 Not open to moving abroad" },
                                ])}
                                {errorMessages["moveAbroad"] && <p style={{ color: "red" }}>{errorMessages["moveAbroad"]}</p>}

                                {renderSelect("How religious are you?", "religious", formData.religious, [
                                    { value: "🤲 Very Religious", label: "🤲 Very Religious" },
                                    { value: "🙏 Moderately Religious", label: "🙏 Moderately Religious" },
                                    { value: "🚫 Not Religious", label: "🚫 Not Religious" },
                                ])}
                                {errorMessages["religious"] && <p style={{ color: "red" }}>{errorMessages["religious"]}</p>}

                                {renderSelect("Do you smoke?", "smoker", formData.smoker, [
                                    { value: "🚬 I am a smoker", label: "🚬 I am a smoker" },
                                    { value: "🚫 Not a smoker", label: "🚫 Not a smoker" },
                                ])}
                                {errorMessages["smoker"] && <p style={{ color: "red" }}>{errorMessages["smoker"]}</p>}

                                {renderSelect("Do you drink alcohol?", "drinker", formData.drinker, [
                                    { value: "🍺 I drink alcohol", label: "🍺 I drink alcohol" },
                                    { value: "🚫 I've never drank alcohol", label: "🚫 I've never drank alcohol" },
                                ])}
                                {errorMessages["drinker"] && <p style={{ color: "red" }}>{errorMessages["drinker"]}</p>}

                                {renderSelect("Do you eat Halal?", "eatHalal", formData.eatHalal, [
                                    { value: "🍽️ I eat only Halal", label: "🍽️ I eat only Halal" },
                                    { value: "🍽️ I'm okay with non-halal", label: "🍽️ I'm okay with non-halal" },
                                ])}
                                {errorMessages["eatHalal"] && <p style={{ color: "red" }}>{errorMessages["eatHalal"]}</p>}

                                {renderSelect("How often do you pray?", "practiceSalat", formData.practiceSalat, [
                                    { value: "🕌 Pray 5 times a day", label: "🕌 Pray 5 times a day" },
                                    { value: "🌄 Pray 3-5 times a day", label: "🌄 Pray 3-5 times a day" },
                                    { value: "🌇 Pray 1-3 times a day", label: "🌇 Pray 1-3 times a day" },
                                    { value: "🚫 Don't pray at all", label: "🚫 Don't pray at all" },
                                ])}
                                {errorMessages["practiceSalat"] && <p style={{ color: "red" }}>{errorMessages["practiceSalat"]}</p>}

                                {renderSelect("What's your religious sector?", "muslimSector", formData.muslimSector, [
                                    { value: "Sunni", label: "Sunni" },
                                    { value: "Shia", label: "Shia" },
                                    { value: "Ahmadi", label: "Ahmadi" },
                                    { value: "Other", label: "Other" },
                                ])}
                                {errorMessages["muslimSector"] && <p style={{ color: "red" }}>{errorMessages["muslimSector"]}</p>}

                                {renderSelect("Which have you completed?", "hajjUmrah", formData.hajjUmrah, [
                                    { value: "🕋 Hajj Completed", label: "🕋 Hajj Completed" },
                                    { value: "🕋 Umrah Completed", label: "🕋 Umrah Completed" },
                                    { value: "Hajj & Umrah Completed", label: "Hajj & Umrah Completed" },
                                    { value: "🚫 Haven't completed Hajj/Umrah", label: "🚫 Haven't completed Hajj/Umrah" },
                                ])}
                                {errorMessages["hajjUmrah"] && <p style={{ color: "red" }}>{errorMessages["hajjUmrah"]}</p>}

                                {renderFileInput("Upload Image 1*", "1", formData.username)}
                                {errorMessages["image1"] && <p style={{ color: "red" }}>{errorMessages["image1"]}</p>}
                                {renderFileInput("Upload Image 2*", "2", formData.username)}
                                <button
                                    type="button"
                                    style={styles.submitButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >
                                    Create Profile
                                </button>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default CreateCandidate;
